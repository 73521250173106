import React from "react"

const LinkedIn = ({ color }) => {
  return (
    <svg
      width="26"
      height="27"
      viewBox="0 0 26 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.10631 21.9576V10.3393H7.97349V21.9576H4.10631ZM8.22461 6.7567C8.22461 7.86161 7.38756 8.76562 6.03153 8.76562H6.01479C4.70898 8.76562 3.87193 7.86161 3.87193 6.7567C3.87193 5.6183 4.74247 4.74777 6.06501 4.74777C7.38756 4.74777 8.20787 5.6183 8.22461 6.7567ZM18.0181 21.9576V15.7467C18.0181 14.173 17.4657 13.1183 16.0762 13.1183C15.0047 13.1183 14.3686 13.8382 14.084 14.5246C13.9835 14.7757 13.9668 15.1272 13.9668 15.4621V21.9576H10.0996C10.1498 11.4275 10.0996 10.3393 10.0996 10.3393H13.9668V12.0301H13.9333C14.4355 11.2266 15.3563 10.0714 17.4322 10.0714C19.9768 10.0714 21.8853 11.7288 21.8853 15.2946V21.9576H18.0181ZM25.853 5.25C25.853 2.58817 23.6934 0.42857 21.0315 0.42857H4.9601C2.29827 0.42857 0.138672 2.58817 0.138672 5.25V21.3214C0.138672 23.9833 2.29827 26.1429 4.9601 26.1429H21.0315C23.6934 26.1429 25.853 23.9833 25.853 21.3214V5.25Z"
        fill={color}
      />
    </svg>
  )
}

export default LinkedIn
