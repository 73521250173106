import React from "react"

const Twitter = ({ color }) => {
  return (
    <svg
      width="28"
      height="22"
      viewBox="0 0 28 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.1899 3.11607C26.2189 3.5346 25.1642 3.83594 24.076 3.95312C25.1977 3.28348 26.0515 2.22879 26.4533 0.973214C25.4153 1.59263 24.2434 2.04464 23.0213 2.27902C22.0336 1.22433 20.6274 0.571428 19.0705 0.571428C16.0738 0.571428 13.6631 2.99888 13.6631 5.97879C13.6631 6.39732 13.7133 6.81585 13.797 7.21763C9.31041 6.98326 5.30929 4.8404 2.64746 1.55915C2.17871 2.36272 1.91085 3.28348 1.91085 4.28795C1.91085 6.16295 2.86509 7.82031 4.32157 8.79129C3.43429 8.75781 2.59724 8.5067 1.87737 8.10491C1.87737 8.12165 1.87737 8.15513 1.87737 8.17187C1.87737 10.8002 3.73563 12.9766 6.21331 13.4788C5.7613 13.596 5.27581 13.6629 4.79032 13.6629C4.43876 13.6629 4.10393 13.6295 3.76911 13.5792C4.4555 15.7221 6.44768 17.279 8.82492 17.3292C6.96666 18.7857 4.63965 19.6395 2.11175 19.6395C1.65974 19.6395 1.24121 19.6228 0.805943 19.5725C3.19992 21.1127 6.0459 22 9.10951 22C19.0537 22 24.4946 13.7634 24.4946 6.61495C24.4946 6.38058 24.4946 6.1462 24.4778 5.91183C25.5325 5.14174 26.4533 4.1875 27.1899 3.11607Z"
        fill={color}
      />
    </svg>
  )
}

export default Twitter
